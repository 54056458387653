//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      historyInfo: {}
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getHistoryInfo();
  },
  mounted: function mounted() {},
  methods: {
    getHistoryInfo: function getHistoryInfo() {
      var _this = this;

      this.$http.get(this.$constant.baseURL + "/webInfo/getHistoryInfo", {}, true).then(function (res) {
        if (!_this.$common.isEmpty(res.data)) {
          _this.historyInfo = res.data;
        }
      }).catch(function (error) {
        _this.$message({
          message: error.message,
          type: "error"
        });
      });
    }
  }
};